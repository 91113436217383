import thunk from "redux-thunk";
import { reducer } from "./reducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { applyMiddleware, legacy_createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = legacy_createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export { store };
export { persistor };
