import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: any) => {
  let isAuth = localStorage.getItem("token");

  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;
