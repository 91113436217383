import axios from "axios";

const REFRESH_TOKEN = localStorage.getItem("refreshToken");
const apiKey = localStorage.getItem("apiKey");

const axiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInterceptor.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers!["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
  }
);

axiosInterceptor.interceptors.response.use(
  async (res: any) => {
    axiosInterceptor.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    console.log(res)
    return res;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response.status === 455 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const res = await axios.post(
          `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
          {
            grant_type: "refresh_token",
            refresh_token: REFRESH_TOKEN,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("refreshToken", res.data.refresh_token);        
        return axiosInterceptor(originalConfig);
      } catch (refreshTokenError) {
        console.log("refreshTokenError", refreshTokenError);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("persist:root");
        return (window.location.href = "#/login");
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInterceptor };
