import { ImCross } from "react-icons/im";
import { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Textarea } from "@material-tailwind/react";
import AddDishFunc from "../../Api/Dishes/AddDishFunc";
import AddTagFunc from "../../Api/Dishes/AddTagFunc";
import CategoryDataFunc from "../../Api/Dishes/CategoryDataFunc";

const AddDish = () => {
  const [tags, setTags] = useState<any>([]);
  const [tagName, setTagName] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [addDish, setAddDish] = useState<string>("");
  const [currCategory, setCurrCategory] = useState("");
  const [category, setCategory] = useState<string[]>([]);
  const [categoryId, setCategoryId] = useState<string>("");
  const [dishDescription, setDishDescription] = useState<string>("");
  const [alternateDishName, setAlternateDishName] = useState<string>("");
  const [alternateCategoryName, setAlternateCategoryName] =
    useState<string>("");

  const Error = (params: any) => toast.error(params);
  const notify = (params: any) => toast.success(params);

  const handleAddDish = () => {
    // Making network call for adding new diCategory
    if (!categoryId) {
      Error("Please select category first");
    } else {
      AddDishFunc(
        addDish,
        alternateDishName,
        dishDescription,
        categoryId,
        currCategory,
        alternateCategoryName
      )
        .then((res) => {
          if (res.data.message === "Dish added successfully") {
            notify("Dish added successfully");
            handleAddTag(res.data.dishId.map((el: any) => el._id)[0]);
          }
        })
        .then(() => {
          setAddDish("");
          setAlternateDishName("");
          setTags("");
        })
        .catch((err) => {
          console.log(err && err.message);
          if (err.response && err.response.status === 400) {
            Error("Please add dish first");
          } else if (err.response && err.response.status === 403) {
            Error("Dish alredy exist");
          } else if (err.response && err.response.status === 401) {
            Error("You are not authorized to perform this operation");
          }
        });
    }
  };

  const handleAddTag = (dishId: any): void => {
    AddTagFunc(categoryId, tags, dishId)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.message));
  };

  const handleAddPreviewTag = () => {
    if (tagName && tagValue) {
      const newTag = {
        tagName: tagName.toLowerCase(),
        tagValue: tagValue.toLowerCase(),
      };
      setTags([...tags, newTag]);
      setTagName("");
      setTagValue("");
    } else {
      if (!tagName) {
        Error("Add tag name");
      } else if (!tagValue) {
        Error("Add tag value first");
      }
    }
  };
  const handelDeletePreview = (props: any) => {
    // Function for deleting mobile numbers from preview
    const newPreview = tags.filter(
      (el: any) =>
        el.tagName !== props.tagName && el.tagValue !== props.tagValue
    );
    setTags(newPreview);
  };

  function getCategoryData() {
    // Fetching categories
    return CategoryDataFunc()
      .then((res) => setCategory(res.data))
      .catch((err) => console.log(err && err.message));
  }

  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <div className="mt-2">
      <div>
        <strong className="flex text-xl mb-3">Add New Dish</strong>
      </div>
      <div className="border p-3 rounded-lg shadow-lg">
        <label className="flex">Category name*</label>
        <select
          defaultValue="Select category"
          aria-label="Category name"
          onChange={(e) => {
            setCategoryId(e.target.value.split(",")[0]);
            setCurrCategory(e.target.value.split(",")[1]);
            setAlternateCategoryName(e.target.value.split(",")[2]);
          }}
          className="w-full px-2 py-2 mt-2 mb-4 text-purple-700 bg-gray-100 rounded-md  focus:ring-purple-300 focus:outline-none"
        >
          <option value="Select category" disabled>
            Select category
          </option>
          {category &&
            category.map((el: any) => (
              <option key={el._id} value={[el._id, el.title, el.alternateName]}>
                {el.title + " / " + el.alternateName}
              </option>
            ))}
        </select>
        <label className="flex">Dish name*</label>
        <input
          aria-label="Dish name"
          onChange={(e) => setAddDish(e.target.value)}
          value={addDish}
          type="text"
          className="block w-full px-4 py-2 mt-2 text-purple-700 bg-gray-100 rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <label className="flex mt-2">Alternate name*</label>
        <input
          aria-label="Local name"
          onChange={(e) => setAlternateDishName(e.target.value)}
          value={alternateDishName}
          type="text"
          className="block w-full px-4 py-2 mt-2 text-purple-700 bg-gray-100 rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <div className="flex flex-col w-full gap-2">
          <label className="flex mt-3">Description</label>
          <Textarea
            onChange={(e) => setDishDescription(e.target.value)}
            size="lg"
            className=" "
          />
        </div>
        <div className="mt-4 items-center">
          <label className="block">Add Tags</label>
          <div className="inline gap-2">
            <input
              aria-label="Tag name"
              placeholder="Tag name"
              onChange={(e) => setTagName(e.target.value)}
              value={tagName}
              type="text"
              className="block w-full mt-1 px-4 py-1 text-purple-700 bg-gray-100 rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="flex gap-2">
            <input
              aria-label="Tag Value"
              placeholder="Tag value"
              onChange={(e) => setTagValue(e.target.value)}
              value={tagValue}
              type="text"
              className="block w-full mt-1 mb-2 px-4 py-1 text-purple-700 bg-gray-100 rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div>
            <button
              onClick={handleAddPreviewTag}
              className="flex px-9 py-1 text-white bg-blue-400 rounded-md focus:outline-none"
            >
              Add
            </button>
          </div>
        </div>
        <div className="mt-6">
          <label className="flex mb-2">Preview Tags</label>
          <div className="border rounded-md w-full bg-gray-100  grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4  gap-4 p-3">
            {tags &&
              tags.map((el: any) => (
                <div
                  key={el}
                  className="flex justify-around items-center border rounded-md bg-white p-1"
                >
                  {el.tagName + " : " + el.tagValue}
                  <ImCross
                    onClick={() => handelDeletePreview(el)}
                    className="min-w-max"
                    style={{
                      color: "#b91c1c",
                      height: "13px",
                      width: "23px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
        <button
          onClick={handleAddDish}
          className="flex mt-4 px-9 py-2 text-white bg-blue-400 rounded-md focus:outline-none"
        >
          Add
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default AddDish;
