import { AddCategories } from "../index";

export default function HandleAddCategoryFunc(
  addCategory: string,
  alternateCategoryName: string
) {
  const newCategory = {
    title: addCategory,
    alternateName: alternateCategoryName,
  };
  return AddCategories(newCategory);
}
