import { AllDishes } from "../index";

export default function FetchAllDishesFunc(
  page: number,
  limit: number,
  search: string,
  filter: string,
  sort: any,
  filterCategory: string
) {
  return AllDishes(page, limit, search, filter, sort, filterCategory);
}
