import { AddMealDish } from "../index";

export default function AddMealDishFunc(
  mealId: any,
  dishName: string,
  alternateName: string
) {
  const reqBody = {
    newDish: dishName,
    alternateName: alternateName,
  };
  return AddMealDish(mealId, reqBody);
}
