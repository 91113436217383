import Meal from "../../Pages/Meal planning/Meal";
import Login from "../../Pages/Login/Login";
import Dishes from "../../Pages/Dishes/Dishes";
import AddDish from "../../Pages/Dishes/AddDish";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Categories from "../../Pages/Categories/Categories";
import { Routes, Route, Navigate } from "react-router-dom";
import AddCategory from "../../Pages/Categories/AddCategory";
import MealPlanning from "../../Pages/Meal planning/MealPlanning";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
// import { lazy } from "react";
// const Login = lazy(() => import('../../Pages/Login/Login'));

const AllRoutes = () => {
  const isLoggedIn = localStorage.getItem('token');
  return (
    <div>
      <Breadcrumbs />
      <Routes>
        <Route
          path="/"
          element={isLoggedIn?
            <PrivateRoute>
              <MealPlanning />
            </PrivateRoute>:<Navigate to='/login'/>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/meal-planning"
          element={isLoggedIn?
            <PrivateRoute>
              <MealPlanning />
            </PrivateRoute>:<Navigate to='/login'/>
          }
        />
        <Route
          path="/meal-planning/meal"
          element={
            <PrivateRoute>
              <Meal />
            </PrivateRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <PrivateRoute>
              <Categories />
            </PrivateRoute>
          }
        />
        <Route
          path="/categories/add-category"
          element={
            <PrivateRoute>
              <AddCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/dishes"
          element={
            <PrivateRoute>
              <Dishes />
            </PrivateRoute>
          }
        />
        <Route
          path="/dishes/add-dish"
          element={
            <PrivateRoute>
              <AddDish />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<h3>Page Not Found</h3>} />
      </Routes>
    </div>
  );
};

export default AllRoutes;
