import { RemoveMealDish } from "../index";

export default function RemoveMealDishFunc(
  mealId: any,
  newDishes: string,
  newDishesWithDetails: string
) {
  const reqBody = {
    newDishes: newDishes,
    newDishesWithDetails: newDishesWithDetails,
  };
  return RemoveMealDish(mealId, reqBody);
}
