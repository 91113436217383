import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useState } from "react";
import { GoHome } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleSidebar } from "../Redux/action";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { HiOutlineClipboardList } from "react-icons/hi";

export default function Sidebar(sidebarFunc: any) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const dispatch = useDispatch();
  const loggedUser = useSelector((store:any)=>store.loggedUser)


  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div>
      <Accordion icon="" open={open === 1}>
        <AccordionHeader
          className={
            open === 1
              ? "border-none p-3 rounded-full rounded-l-none bg-blue-200"
              : "border-none p-3 text-black"
          }
          onClick={() => {
            handleOpen(1);
            navigate("/meal-planning");
            dispatch(toggleSidebar(false));
          }}
        >
          <span>
            <GoHome />
          </span>{" "}
          Meal Planning
        </AccordionHeader>
      </Accordion>
      <Accordion icon="" open={open === 3}>
        <AccordionHeader
          className={
            open === 3
              ? "border-none p-3 rounded-full rounded-l-none bg-blue-200"
              : "border-none p-3 text-black"
          }
          onClick={() => {
            handleOpen(3);
            navigate("/dishes");
            dispatch(toggleSidebar(false));
          }}
        >
          <span>
            <HiOutlineClipboardList />
          </span>
          Dishes
        </AccordionHeader>
      </Accordion>
      <Accordion icon="" open={open === 2}>
        <AccordionHeader
          className={
            open === 2
              ? "border-none p-3 rounded-full rounded-l-none bg-blue-200"
              : "border-none p-3 text-black"
          }
          onClick={() => {
            handleOpen(2);
            navigate("/categories");
            dispatch(toggleSidebar(false));
          }}
        >
          <span>
            <HiOutlineClipboardList />
          </span>{" "}
          Categories
        </AccordionHeader>
      </Accordion>
      <Accordion icon="" open={open === 5}>
        <AccordionHeader
          className={
            open === 5
              ? "border-none p-3 rounded-full rounded-l-none bg-blue-200"
              : "border-none p-3 text-black"
          }
          onClick={() => handleOpen(5)}
        >
          <span>
            <RiAccountPinCircleLine />
          </span>
          {/* {"+"+loggedUser.mobile} */}
          {/* {loggedUser.userName} */}
          {"Account"}
        </AccordionHeader>
        <AccordionBody className="ml-5">
          <div className="inline">
            <div className="text-black">{`Logged in as ${loggedUser.userName} (${loggedUser.role})`}</div>
            <button
              onClick={() => {
                handleLogout();
                dispatch(toggleSidebar(false));
              }}
              className="flex py-2 hover:text-white active:text-white-500 text-black mb-2"
            >
              Logout
            </button>
          </div>
        </AccordionBody>
      </Accordion>
    </div>
  );
}
