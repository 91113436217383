import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { settingMealId } from "../../Redux/action";
import MealsDataFunc from "../../Api/MealPlanning/MealsDataFunc";

function MealCards() {
  const date = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [meals, setMeals] = useState([]);

  // date.setDate(date.getDate() + 1);

  let day = date.getDay();
  let currentDayIndex: any;

  meals &&
    meals.forEach((el: any, i: any) => {
      if (i === day) {
        currentDayIndex = i;
      }
    });

  let data: any = [];
  for (let i = currentDayIndex; i < meals.length; i++) {
    meals && data.push(meals[i]);
  }

  for (let j = 0; j < currentDayIndex; j++) {
    meals && data.push(meals[j]);
  }

  const handleEditMeal = (params: string) => {
    dispatch(settingMealId(params));
    navigate("/meal-planning/meal");
  };

  const getMealsData = () => {
    MealsDataFunc()
      .then((res) => {
        setMeals(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getMealsData();
  }, []);
  let cards =
    data &&
    data.map((a: any) => (
      <div
        className="border shadow-md border-blue-100 mb-3 rounded-md p-2"
        key={a.day}
        onClick={() => handleEditMeal(a._id)}
      >
        <div className="flex justify-between">
          <div className="bg-gray-300 border rounded-md px-2">{a.day}</div>
          <div>{a.id}</div>
          <div>{a.date}</div>
        </div>
        <div className="mb-1 mt-1">{`${a.day}'s special`}</div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 border border-gray-300 bg-gray-100 rounded-md p-2">
          {a.dishesWithDetails.map((el: any) => (
            <div
              key={el.dishName}
              className="border rounded-md bg-white px-3 py-1"
            >
              {el.dishName}
              {el.alternateName ? " / " + el.alternateName : ""}
            </div>
          ))}
        </div>
      </div>
    ));
  return <div>{cards}</div>;
}

export default MealCards;
