import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 
import {getStorage} from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyDN1ShkD3jVNlrpl3pbMOCSOB7ARVBBinU",
  authDomain: "annamandir-crm.firebaseapp.com",
  projectId: "annamandir-crm",
  storageBucket: "annamandir-crm.appspot.com",
  messagingSenderId: "955107458065",
  appId: "1:955107458065:web:3c312c3eb911d45e21c5b0",
  measurementId: "G-NR0R30YVQZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const getDb = getFirestore(app)
export const storage = getStorage(app)
export default app;

