import { Broadcast } from "../index";

export default function BroadcastFunc(
  dish: any,
  mealImageURL: string,
  messageTemplate: string
) {
  let params;
  if (!messageTemplate || messageTemplate === "test") {
    params = {
      elementName: test,
      dishes: Object.keys(dish).length && dish.dishes.join("," + " "),
      mealImageURL: mealImageURL,
    };
  } else if(messageTemplate === "daily_special") {
    params = {
      elementName: messageTemplate,
      dishes: Object.keys(dish).length && dish.dishes.join("," + " "),
    };
  }

  return Broadcast(params);
}
