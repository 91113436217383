import * as types from "./actionTypes";

const initialState = {
  mealId: "",
  userData:<any> [],
  loggedUser:[],
  isError: false,
  isLoading: false,
  showSidebar: false,
};

const reducer = (oldState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
      };
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        userData: payload,
      };
    case types.GET_USER_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
      };
    case types.MEAL_ID_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        mealId: payload,
      };
    case types.TOGGLE_SIDEBAR:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        showSidebar: payload,
      };
    case types.GET_LOGGED_USER_DETAILS:
      return{
        ...oldState,
        isLoading:false,
        isError:false,
        loggedUser:payload
      }
    default:
      return oldState;
  }
};

export { reducer };
