import { AddTag } from '../index'

export default function AddTagFunc(categoryId:string,tags:string,dishId:any) {
  const newTag = {
    categoryId: categoryId,
    dishId: dishId,
    tags: tags,
  };
  return AddTag(newTag)
  
}
