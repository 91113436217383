import { axiosInterceptor } from "../Services/axios";

// Login page api's

export function login(number: number) {
  return axiosInterceptor.get(`/signup/user-data/?mobile=${number}`);
}
// Dishes page api's

export function AllDishes(
  page: number,
  limit: number,
  search: string,
  filter: string,
  sort: any,
  filterCategory: string
) {
  return axiosInterceptor.get(
    `/dish/all?page=${page}&limit=${limit}&search=${search}&filterByStatus=${
      filter && filter
    }&sort=${sort && sort}&filterCategory=${filterCategory}`
  );
}

export function EditDishes(currDishId: string, currDishStatus: boolean) {
  return axiosInterceptor.put(`/dish/edit/${currDishId}`, {
    isActive: !currDishStatus,
  });
}

export function RemoveDish(currDishId: string) {
  return axiosInterceptor.delete(`/dish/delete/${currDishId}`);
}

export function DeleteTag(currDishId: string) {
  return axiosInterceptor.delete(`/tags/delete/${currDishId}`);
}

export function SingleTag(currDishId: string) {
  return axiosInterceptor.post(`/tags/single-dish`, { dishId: currDishId });
}

export function EditTags(currDishId: string, currDishTags: string) {
  return axiosInterceptor.put(`/tags/edit/${currDishId}`, {
    tags: currDishTags,
  });
}

// Add dishes page api's

export function AddDishes(newDish: any) {
  return axiosInterceptor.post(`/dish/post`, newDish);
}

export function AddTag(newTag: object) {
  return axiosInterceptor.post(`/tags/add`, newTag);
}

// Categories page api's

export function AllCategory(
  page: number,
  limit: number,
  search: string,
  filter: string,
  sort: any
) {
  return axiosInterceptor.get(
    `/category/fetch?page=${page}&limit=${limit}&search=${search}&filter=${
      filter && filter
    }&sort=${sort && sort}`
  );
}

export function CategoryEdit(
  selectedCategoryId: string,
  currCategoryStatus: boolean
) {
  return axiosInterceptor.put(`/category/edit/${selectedCategoryId}`, {
    isActive: !currCategoryStatus,
  });
}

export function RemoveCategory(selectedCategoryId: string) {
  return axiosInterceptor.delete(`/category/delete/${selectedCategoryId}`);
}

// Add category page api's

export function AddCategories(newCategory: object) {
  return axiosInterceptor.post(`/category/post`, newCategory);
}

// meal-planning page api's

export function MealsData() {
  return axiosInterceptor.get(`/meals`);
}

// Single meal page api's

export function MealData(mealId: any) {
  return axiosInterceptor.get(`/meals/single-meal/?mealId=${mealId}`);
}

export function CategoryData() {
  return axiosInterceptor.get(`/category/get`);
}

export function DishFetch(categoryId: any) {
  return axiosInterceptor.post(`/dish/get`, categoryId);
}

export function AddMealDish(
  mealId: any,
  reqBody:object
) {
  return axiosInterceptor.post(`/meals/add-dish/${mealId}`, reqBody);
}

export function RemoveMealDish(
  mealId: any,
  reqBody:object
) {
  return axiosInterceptor.put(`/meals/single-meal/edit/${mealId}`, reqBody);
}

export function updateMealImageURL(
  mealId: any,
  reqBody:object
) {
  return axiosInterceptor.put(`/meals/single-meal/url/edit/${mealId}`, reqBody);
}

export function ClearMealDishes(mealId: any) {
  return axiosInterceptor.put(`/meals/single-meal/delete/${mealId}`, {});
}

export function TestBroadcast(params: any) {
  return axiosInterceptor.post(`/final-test`, params);
}

export function Broadcast(params: any) {
  return axiosInterceptor.post(`/final-broadcast`, params);
}
