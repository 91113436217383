import { AddDishes } from "../index";

export default function AddDishFunc(
  addDish: string,
  alternateDishName: string,
  dishDescription: string,
  categoryId: string,
  currCategory: string,
  alternateCategoryName: string
) {
  const newDish = {
    categoryId: categoryId,
    categoryName: currCategory,
    alternateCategoryName: alternateCategoryName,
    dishName: addDish,
    alternateDishName: alternateDishName,
    dishDetail: dishDescription,
  };
  return AddDishes(newDish);
}
