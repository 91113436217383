import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Pagination from "../../Components/Pagination/Pagination";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import { FetchAllCategoryFunc } from "../../Api/Categories/FetchAllCategoryFunc";
import HandleEditCategoryFunc from "../../Api/Categories/HandleEditCategoryFunc";
import HandleRemoveCategoryFunc from "../../Api/Categories/HandleRemoveCategoryFunc";

const Categories = () => {
  const [sort, setSort] = useState("");
  const [limit] = useState<number>(8);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(1);
  const [selected, setSelected] = useState("");
  const [removeBtn, setRemoveBtn] = useState(false);
  const [totalPage, setTotalPage] = useState<number>();
  const [showAddBtn, setShowAddBtn] = useState<boolean>();
  const [fetchCategory, setFetchCategory] = useState<any[]>([]);
  const [currCategoryStatus, setCurrCategoryStatus] = useState<any>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

  const navigate = useNavigate(); 
  const Error = (params: any) => toast.error(params);
  const notify = (params: any) => toast.success(params);
  const role = useSelector((store: any) => store.loggedUser.role);

  const fetchAllCategory = () => {
    return FetchAllCategoryFunc(page, limit, search, filter, sort)
      .then((res) => {
        setFetchCategory(res.data.result);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err && err.message);
      });
  };

  const handleAddCategory = () => {
    navigate("/categories/add-category");
  };

  const handlePageChange = (val: number) => {
    setPage(val);
  };

  const handleCategoryEdit = (): void => {
    // Making network request for editing categories
    HandleEditCategoryFunc(selectedCategoryId, currCategoryStatus)
      .then((res) => {
        fetchAllCategory();
        setCurrCategoryStatus(!currCategoryStatus);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        }
      });
  };

  const handleRemoveCategory = () => {
    HandleRemoveCategoryFunc(selectedCategoryId)
      .then((res) => {
        if (res.data === "Category deleted") {
          notify("Category deleted successfully");
          fetchAllCategory();
          setRemoveBtn(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response.status === 403) {
          Error("First delete all the dishes from this category");
        } else if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        }
      });
  };

  const handleSelect = (id: string) => {
    if (id === selectedCategoryId) {
      setRemoveBtn(!removeBtn);
      if (selected !== "") {
        setSelected("");
      } else {
        setSelected(selectedCategoryId);
      }
    } else {
      setRemoveBtn(true);
    }
  };

  useEffect(() => {
    if (role && role === "super-admin") {
      setShowAddBtn(true);
    } else if (role && role === "admin") {
      setShowAddBtn(false);
    }
    fetchAllCategory();
  }, [page, currCategoryStatus, search, filter, sort]);
  return (
    <div>
      <div>
        <strong className="flex text-xl mb-4">Categories</strong>
      </div>
      <Toaster />
      <div>
        <div className="lg:flex md:flex lg:justify-between md:justify-between lg:px-3 md:px-3 md:items-center lg:items-center">
          <div className="mb-2 mt-5 md:mt-0 lg:mt-0">
            <DebounceInput
              minLength={2}
              debounceTimeout={500}
              aria-label="Search dish by name"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Search category by name"
              type="text"
              className="block w-full px-4 py-2 mt-2 border-2 border-gray-400 rounded-md  focus:outline-none"
            />
          </div>
          <div className="lg:flex gap-4">
            <div>
              <select
                onChange={(e) => {
                  setFilter(e.target.value);
                  setPage(1);
                }}
                defaultValue="Filter"
                aria-label="Filter"
                className="w-full mb-2 lg:mb-0 md:mb-0  px-2 py-2 border-2 border-gray-400 rounded-md  focus:ring-purple-300 focus:outline-none"
              >
                <option value="Filter" disabled>
                  Filter by status
                </option>
                <option value="active">Active</option>
                <option value="not active">Not active</option>
              </select>
            </div>
            <div>
              <select
                onChange={(e) => {
                  setSort(e.target.value);
                  setPage(1);
                }}
                defaultValue="SortBy"
                aria-label="SortBy"
                className="w-full mb-2 lg:mb-0 md:mb-0  px-2 py-2 border-2 border-gray-400 rounded-md  focus:ring-purple-300 focus:outline-none"
              >
                <option value="SortBy" disabled>
                  SortBy
                </option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </div>
        {showAddBtn ? (
          <div className="lg:px-3 md:px-3">
            {removeBtn === false ? (
              <button
                onClick={handleAddCategory}
                className=" px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
              >
                Add new category
              </button>
            ) : (
              <div>
                <button
                  onClick={handleRemoveCategory}
                  className="px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
                >
                  Remove
                </button>
                <button
                  onClick={handleCategoryEdit}
                  className="ml-2 px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
                >
                  {currCategoryStatus === true ? "Not active" : "Active"}
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="mt-3">
        <table className="w-full table-auto border-separate border-spacing-3">
          <thead>
            <tr>
              <th className="border px-1 border-slate-300">Category</th>
              <th className="border px-1 border-slate-300">Status</th>
            </tr>
          </thead>
          <tbody>
            {fetchCategory.map((el: any) => (
              <tr key={el.category.id}>
                <td
                  onClick={() => {
                    setSelectedCategoryId(el.category.id);
                    setSelected(el.category.id);
                    handleSelect(el.category.id);
                    setCurrCategoryStatus(el.category.Active);
                  }}
                  className={
                    selected === el.category.id
                      ? "bg-gray-300 border rounded-md px-2 cursor-pointer"
                      : "border rounded-md cursor-pointer px-2"
                  }
                  key={el.category.id}
                >
                  <strong>
                    {el.category.name} {"/"}{" "}
                    <span className="text-red-200">
                      {el.category.alternateName}
                    </span>
                  </strong>
                </td>
                <td className="flex justify-around border rounded-md px-2">
                  {el.category.Active === true ? "Active" : "Not active"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-5">
        <Pagination
          totalPages={totalPage}
          currentPage={page}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Categories;
