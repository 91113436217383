import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import HandleAddCategoryFunc from "../../Api/Categories/HandleAddCategoryFunc";

const AddCategory = () => {
  const [addCategory, setAddCategory] = useState<string>("");
  const [alternateCategoryName, setAlternateCategoryName] =
    useState<string>("");
  
  const Error = (params: any) => toast.error(params);
  const notify = (params: any) => toast.success(params);

  const handleAddCategory = (): void => {
    // Making network request for addind new category    
    HandleAddCategoryFunc(addCategory,alternateCategoryName)    
      .then((res) => {
        if (res.data === "Category added successfully") {
          notify("Category added successfully");
        }
      })
      .then(() => {
        setAddCategory("")
        setAlternateCategoryName("")
      })
      .catch((err) => {
        console.log(err.message);
        if (err && err.response.status === 400) {
          Error("Please fill all fields first");
        }else if (err && err.response.status === 403) {
          Error("category alredy exist");
        } else if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        }
      });
  };

  return (
    <div className="mt-9">
      <div>
        <strong className="flex text-xl mb-5">Add New Category</strong>
      </div>
      <div className="w-full border p-3 rounded-lg shadow-lg">
        <label className="flex">Category name*</label>
        <input
          aria-label="Category name"
          onChange={(e) => setAddCategory(e.target.value)}
          value={addCategory}
          type="text"
          className="block w-full  px-4 py-2 mt-2 text-purple-700 bg-gray-100 border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <label className="flex mt-2">Alternate name*</label>
        <input
          aria-label="Alternate name"
          onChange={(e) => setAlternateCategoryName(e.target.value)}
          value={alternateCategoryName}
          type="text"
          className="block w-full  px-4 py-2 mt-2 text-purple-700 bg-gray-100 border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <button
          onClick={handleAddCategory}
          className="flex mt-4 px-9 py-2 text-white bg-blue-400 rounded-md focus:outline-none"
        >
          Add
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default AddCategory;
