import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { ImCross } from "react-icons/im";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import GetMealDataFunc from "../../Api/MealPlanning/GetMealDataFunc";
import CategoryDataFunc from "../../Api/Dishes/CategoryDataFunc";
import DishFetchFunc from "../../Api/MealPlanning/DishFetchFunc";
import AddMealDishFunc from "../../Api/MealPlanning/AddMealDishFunc";
import RemoveMealDishFunc from "../../Api/MealPlanning/RemoveMealDishFunc";
import ClearMealDishesFunc from "../../Api/MealPlanning/ClearMealDishesFunc";
import TestBroadcastFunc from "../../Api/MealPlanning/TestBroadcastFunc";
import BroadcastFunc from "../../Api/MealPlanning/BroadcastFunc";
import { storage } from "../../firebase.config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";
import axios from "axios";
import UpdateMealImageUrlFunc from "../../Api/MealPlanning/UpdateMealImageUrlFunc";
import { axiosInterceptor } from "../../Services/axios";

const Meal = () => {
  const Error = (params: any) => toast.error(params);
  const notify = (params: any) => toast.success(params);
  const mobileNo = useSelector(
    (store: any) => store.userData.phoneNumber?.split("+")[1]
  );
  const role = useSelector((store: any) => store.loggedUser.role);

  const [dish, setDish] = useState<any>({});
  const [dishes, setDishes] = useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const [showConfModal, setShowConfModal] = useState(false);
  const [confModal, setConfModal] = useState("");
  const [confModalContent, setConfModalCont] = useState("");
  const [category, setCategory] = useState<string[]>([]);
  const [showAddBtn, setShowAddBtn] = useState<boolean>();
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [imageUploadURL, setImageUploadURL] = useState("");
  const [messageTemplate, setMessageTemplate] = useState("");
  const [dbName,setDbName] = useState(null)
  const [testBtn, setTestBtn] = useState(false);
  const mealId = useSelector((store: any) => store.mealId);
  
  console.log(dish.day)
  const upload = () => {
    if (imageUpload === null) return;
    const storage = getStorage();   
    const imgRef = ref(
      storage,
      `images/${dbName}/${dish.day}/${dish.day}_meal_image`
    );
    uploadBytes(imgRef, imageUpload)
      .then(() => {
        notify("Image uploaded");
        getDownloadURL(imgRef)
          .then((url: any) => {
            setImageUploadURL(url);
            updateImageURL(url);
            setTestBtn(true)
          })
          .catch((error) => {
            console.error("Error getting image URL: ", error);
          });
      })
      .catch((err) => console.log(err));
  };

  const getDbName = ()=>{
    axiosInterceptor.get(`/db-name`)
    .then((res)=>setDbName(res.data))
    .catch((err)=>console.log(err))
  }

  const getMealData = () => {
    GetMealDataFunc(mealId)
      .then((res) => {
        setDish(res.data[0]);

        res.data[0].imageURL && setImageUploadURL(res.data[0].imageURL);
        res.data[0].imageURL && setTestBtn(true);
        
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updateImageURL = (url: string) => {
    UpdateMealImageUrlFunc(mealId, url).then((res) => {
      console.log("rrr", res);
      getMealData();
      setTestBtn(true);
    });
  };
  const getCategoryData = () => {
    CategoryDataFunc()
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDishFetch = (e: any) => {
    // Making network request for fetching dishes
    DishFetchFunc(e.target.value)
      .then((res) => {
        setDishes(res.data.DishData);
      })
      .catch((err) => console.log(err));
  };

  const handleAddDish = (dishName: string, alternateName: string) => {
    AddMealDishFunc(mealId, dishName, alternateName)
      .then((res) => {
        getMealData();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        } else if (err.response.status === 403) {
          Error("Dish already exists");
        }
      });
  };

  const handelRemoveDish = (params: string) => {
    if (Object.keys(dish).length) {
      const newDishes = dish.dishes.filter((el: any) => el !== params);
      const newDishesWithDetails = dish.dishesWithDetails.filter(
        (el: any) => el.dishName !== params
      );
      RemoveMealDishFunc(mealId, newDishes, newDishesWithDetails)
        .then((res) => {
          getMealData();
        })
        .catch((err) => {
          console.log(err.message);
          if (err.response.status === 401) {
            Error("You are not authorized to perform this operation");
          }
        });
    }
  };

  const handleClearDishes = () => {
    ClearMealDishesFunc(mealId)
      .then((res) => {
        if (res.data === "dishes cleared successfully") {
          notify(res.data);
          getMealData();
        }
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        }
      });
  };

  const handleTestBroadcast = () => {
    TestBroadcastFunc(dish, mobileNo, imageUploadURL, messageTemplate)
      .then((res) => {
        if (res.data === "Test message sent successful") {
          notify("Test message sent successfully");
        }
      })
      .catch((err) => console.log(err.message));
  };

  const handleBroadcast = () => {
    // Network request for final broadcast
    BroadcastFunc(dish, imageUploadURL, messageTemplate)
      .then((res) => {
        if (res.data === "Message sent to all customers") {
          notify("Message sent to all customers");
        } else if (res.data === "This api is disabled for now") {
          Error("This api is disabled for now");
        }
      })
      .catch((err) => console.log(err.message));
  };
  // console.log("ss",dish.imageURL)

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? "#42a5f5" : "white",
    color: isDragging ? "white" : "black",
    ...draggableStyle,
  });

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    const items = Array.from(dish.dishesWithDetails);
    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);
    dish.dishesWithDetails = items;

    const temp = Array.from(dish.dishes);
    const [tempOrder] = temp.splice(source.index, 1);
    temp.splice(destination.index, 0, tempOrder);
    dish.dishes = temp;
  };

  useEffect(() => {
    if (role && role === "super-admin") {
      setShowAddBtn(true);
    } else if (role && role === "admin") {
      setShowAddBtn(false);
    }
    getMealData();
    getCategoryData();
    getDbName()
  }, []);

  return (
    <div>
      <div>
        <strong className="flex text-xl">Meal</strong>
      </div>
      <div>
        <strong>{Object.keys(dish).length && dish.day}</strong>
      </div>
      <div className=" justify-between gap-6">
        <div className="lg:flex gap-3">
          <div className="mt-3">
            <div className="border rounded-md w-full lg:w-[40rem] bg-gray-100 p-3">
              {showAddBtn ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="dishName">
                    {(provided) => (
                      <div
                        className=" grid grid-cols-1   gap-4"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {Object.keys(dish).length &&
                          dish.dishesWithDetails.map((el: any, index: any) => {
                            return (
                              <Draggable
                                key={el.dishName}
                                draggableId={el.dishName}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    key={el.dishName}
                                    className="flex justify-between items-center border rounded-md bg-white px-3 py-1"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {el.dishName}
                                    {el.alternateName
                                      ? " / " + el.alternateName
                                      : ""}
                                    <ImCross
                                      onClick={() =>
                                        handelRemoveDish(el.dishName)
                                      }
                                      className="min-w-max"
                                      style={{
                                        color: "#b91c1c",
                                        height: "13px",
                                        width: "23px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className=" grid grid-cols-1   gap-4">
                  {Object.keys(dish).length &&
                    dish.dishesWithDetails.map((el: any) => (
                      <div
                        key={el.dishName}
                        className="flex justify-between items-center border rounded-md bg-white px-3 py-1"
                      >
                        {el.dishName}
                        {el.alternateName ? " / " + el.alternateName : ""}
                      </div>
                    ))}
                </div>
              )}
              {showAddBtn ? (
                <div>
                  <button
                    onClick={() => setShowModal(true)}
                    className="w-full mt-4 px-9 py-2 text-white bg-blue-400 rounded-md focus:outline-none"
                  >
                    Add Dish
                  </button>
                  {showModal ? (
                    <>
                      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-xl min-w-[300px]">
                          {/*content*/}
                          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                              <h3 className="text-3xl font-semibold">
                                Add Dish
                              </h3>
                              <button
                                aria-label="close"
                                className="p-1 ml-auto bg-transparent text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                              >
                                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                  <ImCross
                                    style={{
                                      color: "#b91c1c",
                                      height: "17px",
                                      width: "23px",
                                      cursor: "pointer",
                                      marginTop: "5px",
                                    }}
                                  />
                                </span>
                              </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                              <div className="border p-3 rounded-lg shadow-lg">
                                <label className="flex">Category name*</label>
                                <select
                                  defaultValue="Select category"
                                  aria-label="Category name"
                                  onChange={handleDishFetch}
                                  className="w-full px-2 py-2 mt-2 mb-4 text-purple-700 bg-gray-100 rounded-md  focus:ring-purple-300 focus:outline-none"
                                >
                                  <option value="Select category" disabled>
                                    Select category
                                  </option>
                                  {category &&
                                    category.map((el: any) => (
                                      <option
                                        disabled={
                                          el.isActive === true ? false : true
                                        }
                                        key={el._id}
                                        value={el._id}
                                      >
                                        {el.title}
                                        {" / "}
                                        {el.alternateName}
                                      </option>
                                    ))}
                                </select>
                                <div>
                                  <label className="flex mb-2">
                                    Select Dishes*
                                  </label>
                                  <div className="border rounded-md  bg-gray-100  grid grid-cols-1 gap-4 p-3">
                                    {dishes &&
                                      dishes.map((el: any) =>
                                        el.isActive === true ? (
                                          <div
                                            key={el._id}
                                            onClick={() => {
                                              handleAddDish(
                                                el.dishName,
                                                el.alternateDishName
                                              );
                                              setShowModal(false);
                                              setDishes("");
                                            }}
                                            className="text-center border rounded-md p-1 cursor-pointer bg-white"
                                          >
                                            {el.dishName +
                                              " / " +
                                              el.alternateDishName}
                                          </div>
                                        ) : (
                                          <button
                                            key={el._id}
                                            className="border border-gray-500 rounded-md"
                                            disabled
                                          >
                                            {el.dishName}
                                          </button>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*footer*/}
                            <div>
                              <button
                                className="text-red-900 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="border mt-3 p-3 rounded-lg shadow-lg lg:w-[40rem]">
              <label className="flex">Select Message Template*</label>
              <select
                defaultValue="test"
                aria-label="Select Template"
                onChange={(e) => setMessageTemplate(e.target.value)}
                className="w-full px-2 py-2 mt-2 mb-4 bg-gray-100 rounded-md focus:outline-none"
              >
                <option value="Select Template" disabled>
                  Select Template
                </option>
                <option value="test">Message with Image</option>
                <option value="daily_special" disabled>
                  Message without Image
                </option>
              </select>
            </div>
            <div className="w-full gap-4 lg:flex md:hidden hidden lg:w-[40rem]">
              {showAddBtn ? (
                <button
                  onClick={() => {
                    if (dish.dishes.length !== 0) {
                      setShowConfModal(true);
                      setConfModal("test");
                      setConfModalCont(
                        "Are you sure you want to clear all the dishes"
                      );
                    } else {
                      Error("Already empty");
                    }
                  }}
                  className="px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2 w-full text-white bg-blue-400 rounded-md focus:outline-none"
                >
                  Clear
                </button>
              ) : (
                ""
              )}
              <button
                onClick={() => {
                  if (dish.dishes.length !== 0) {
                    setShowConfModal(true);
                    setConfModal("testBroadcast");
                    setConfModalCont("Are you sure you want to send test message");
                  }else if(!dish.imageURL)
                  {
                    Error("Upload any image first")
                  } else {
                    Error("Add some dishes first");
                  }
                }}
                className="px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2 w-full text-white bg-blue-400 rounded-md focus:outline-none"
              >
                Test
              </button>
              <button
                onClick={() => {
                  if (dish.dishes.length !== 0) {
                    setShowConfModal(true);
                    setConfModal("broadcast");
                    setConfModalCont(
                      "Are you sure you want to send message to all customers"
                    );
                  }else if(!dish.imageURL)
                  {
                    Error("Upload any image first")
                  }
                  else{
                    Error("Add some dishes first");
                  }
                }}
                className="px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2  w-full text-white bg-blue-400 rounded-md focus:outline-none"
              >
                Broadcast
              </button>
            </div>
          </div>
          <div>
            {dish.imageURL ? (
              <div className="border rounded-md mt-4 mx-auto max-w-[320px] max-h-[300px]">
                <img
                  src={dish.imageURL && dish.imageURL}
                  alt="meal_url"
                  height={"30%"}
                  style={{ borderRadius: "5px", margin: "auto" }}
                />
              </div>
            ) : null}
            <div className="mt-2">
              <label>
                <strong>Upload Meals Image</strong>
              </label>
              <div className="bg-gray-200 p-3 rounded-md mt-2">
                <input
                  type="file"
                  name="filename"
                  onChange={(e: any) => setImageUpload(e.target.files[0])}
                  className="mt-3"
                />
              </div>
              <button
                className="px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2 w-full text-white bg-blue-400 rounded-md focus:outline-none"
                onClick={() => {
                  setShowConfModal(true);
                  setConfModal("uploadImage");
                  setConfModalCont(
                    "Are you sure you want to upload this image"
                  );
                }}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 lg:hidden lg:w-[40rem]">
          {showAddBtn ? (
            <button
              onClick={() => {
                if (dish.dishes.length !== 0) {
                  setShowConfModal(true);
                  setConfModal("test");
                  setConfModalCont(
                    "Are you sure you want to clear all the dishes"
                  );
                } else {
                  Error("Already empty");
                }
              }}
              className="px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2 w-full text-white bg-blue-400 rounded-md focus:outline-none"
            >
              Clear
            </button>
          ) : (
            ""
          )}
          <button
            onClick={() => {
              if (dish.dishes.length !== 0) {
                setShowConfModal(true);
                setConfModal("testBroadcast");
                setConfModalCont("Are you sure you want to send test message");
              }else if(!dish.imageURL)
              {
                Error("Upload any image first")
              } else {
                Error("Add some dishes first");
              }
            }}
            className={"px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2 w-full text-white bg-blue-400 rounded-md focus:outline-none"}
          >
            Test
          </button>
          <button
            onClick={() => {
              if (dish.dishes.length !== 0) {
                setShowConfModal(true);
                setConfModal("broadcast");
                setConfModalCont(
                  "Are you sure you want to send message to all customers"
                );
              }else if(!dish.imageURL)
              {
                Error("Upload any image first")
              }
              else{
                Error("Add some dishes first");
              }              
            }}
            className="px-4 py-1 mt-2 md:mt-4 md:px-9 md:py-2  w-full text-white bg-blue-400 rounded-md focus:outline-none"
          >
            Broadcast
          </button>
        </div>
      </div>

      {showConfModal ? (
        <>
          <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-xl min-w-[300px]">
              {/*content*/}
              <div className="max-w-[90%] m-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                {/*body*/}
                <div className="p-4 flex-auto">
                  <div>{confModalContent}</div>
                </div>
                {/*footer*/}
                <div className="flex justify-end px-3">
                  <button
                    className="text-white bg-red-900 rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowConfModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="text-white bg-blue-600 rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowConfModal(false);
                      if (confModal === "test") {
                        handleClearDishes();
                      } else if (confModal === "testBroadcast") {
                        handleTestBroadcast();
                      } else if (confModal === "broadcast") {
                        handleBroadcast();
                      } else if (confModal === "uploadImage") {
                        upload();
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <Toaster />
    </div>
  );
};

export default Meal;
