import { ImCross } from "react-icons/im";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Pagination from "../../Components/Pagination/Pagination";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import FetchAllDishesFunc from "../../Api/Dishes/FetchAllDishesFunc";
import EditDishesFunc from "../../Api/Dishes/EditDishesFunc";
import RemoveDishFunc from "../../Api/Dishes/RemoveDishFunc";
import DeleteTagFunc from "../../Api/Dishes/DeleteTagFunc";
import GetSingleTagFunc from "../../Api/Dishes/GetSingleTagFunc";
import EditTagsFunc from "../../Api/Dishes/EditTagsFunc";
import CategoryDataFunc from "../../Api/Dishes/CategoryDataFunc";

const Dishes = () => {
  const navigate = useNavigate();
  const Error = (params: any) => toast.error(params);
  const notify = (params: any) => toast.success(params);
  const role = useSelector((store: any) => store.loggedUser.role);

  const [limit] = useState<number>(5);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState<number>(1);
  const [selected, setSelected] = useState("");
  const [removeBtn, setRemoveBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalPage, setTotalPage] = useState<number>();
  const [category, setCategory] = useState<string[]>([]);
  const [showAddBtn, setShowAddBtn] = useState<boolean>();
  const [filterCategory, setFilterCategory] = useState("");
  const [currDishId, setCurrDishId] = useState<string>("");
  const [fetchDishes, setFetchDishes] = useState<any[]>([]);
  const [currDishTags, setCurrDishTags] = useState<any>([]);
  const [currDishStatus, setCurrDishStatus] = useState<any>("");

  const handlePageChange = (val: number) => {
    setPage(val);
  };

  const fetchAllDishes = () => {
    return FetchAllDishesFunc(page, limit, search, filter, sort, filterCategory)
      .then((res) => {
        setFetchDishes(res.data.dishes);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err && err.message);
      });
  };

  const handleEditDishes = () => {
    EditDishesFunc(currDishId, currDishStatus)
      .then((res) => {
        setCurrDishStatus(!currDishStatus);
        fetchAllDishes();
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        }
      });
  };

  const handleAddDishes = () => {
    navigate("/dishes/add-dish");
  };

  const handleRemoveDish = () => {
    RemoveDishFunc(currDishId)
      .then((res) => {
        if (res.data === "Dish deleted") {
          notify("Dish deleted successfully");
          fetchAllDishes();
          setRemoveBtn(false);
          handleDeleteTag();
        }
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response.status === 400) {
          Error("Please add Dish");
        } else if (err.response.status === 403) {
          Error("First delete this dishes from meal");
        } else if (err.response.status === 404) {
          Error("Dish alredy exist");
        } else if (err.response.status === 401) {
          Error("You are not authorized to perform this operation");
        }
      });
  };

  const handleDeleteTag = () => {
    DeleteTagFunc(currDishId)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.message));
  };

  const handleSelect = (id: string) => {
    if (id === currDishId) {
      setRemoveBtn(!removeBtn);
      if (selected !== "") {
        setSelected("");
      } else {
        setSelected(currDishId);
      }
    } else {
      setRemoveBtn(true);
    }
  };

  const getSingleTag = () => {
    GetSingleTagFunc(currDishId)
      .then((res) => setCurrDishTags(res.data.map((el: any) => el.tags)))
      .catch((err) => console.log(err.message));
  };

  const handleEditTags = () => {
    EditTagsFunc(currDishId, currDishTags)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.message));
  };

  function getCategoryData() {
    // Fetching categories
    return CategoryDataFunc()
      .then((res) => setCategory(res.data))
      .catch((err) => console.log(err && err.message));
  }

  useEffect(() => {
    if (role && role === "super-admin") {
      setShowAddBtn(true);
    } else if (role && role === "admin") {
      setShowAddBtn(false);
    }
    fetchAllDishes();
    getCategoryData();
  }, [page, currDishStatus, search, filter, filterCategory, sort]);

  return (
    <div className="mt-[-10px] lg:mt-0">
      <div>
        <strong className="flex text-xl mb-3">Dishes</strong>
      </div>
      <Toaster />
      <div>
        <div className="lg:flex md:flex lg:justify-between md:justify-between lg:px-3 md:px-3 md:items-center lg:items-center">
          <div className="mb-2 mt-5 lg:mt-0 md:mt-0">
            <DebounceInput
              type="text"
              minLength={1}
              value={search}
              debounceTimeout={500}
              aria-label="Search dish by name"
              placeholder="Search dish by name"
              onChange={(e) => setSearch(e.target.value)}
              className="block w-full px-4 py-2 mt-2 border-2 border-gray-400 rounded-md focus:outline-none focus:ring-opacity-40"
            />
          </div>
          <div className="lg:flex gap-4">
            <div>
              <select
                onChange={(e) => {
                  setFilterCategory(e.target.value);
                  setPage(1);
                }}
                aria-label="Filter by category"
                defaultValue="Filter by category"
                className="w-full mb-2 lg:mb-0 md:mb-0  px-2 py-2 border-2 border-gray-400 rounded-md  focus:ring-purple-300 focus:outline-none"
              >
                <option value="Filter by category" disabled>
                  Filter by category
                </option>
                {category &&
                  category.map((el: any) => (
                    <option key={el._id} value={el._id}>
                      {el.title}
                      {" / "}
                      {el.alternateName}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <select
                onChange={(e) => {
                  setFilter(e.target.value);
                  setPage(1);
                }}
                aria-label="Filter"
                defaultValue="Filter"
                className="w-full mb-2 lg:mb-0 md:mb-0  px-2 py-2 border-2 border-gray-400 rounded-md  focus:ring-purple-300 focus:outline-none"
              >
                <option value="Filter" disabled>
                  Filter by status
                </option>
                <option value="active">Active</option>
                <option value="not active">Not active</option>
              </select>
            </div>
            <div>
              <select
                onChange={(e) => {
                  setSort(e.target.value);
                  setPage(1);
                }}
                aria-label="SortBy"
                defaultValue="SortBy"
                className="w-full mb-2 lg:mb-0 md:mb-0  px-2 py-2 border-2 border-gray-400 rounded-md  focus:ring-purple-300 focus:outline-none"
              >
                <option value="SortBy" disabled>
                  SortBy
                </option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </div>
        {showAddBtn ? (
          <div className="lg:px-3">
            {removeBtn === false ? (
              <button
                onClick={handleAddDishes}
                className=" px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
              >
                Add new dish
              </button>
            ) : (
              <div className="flex gap-2">
                <button
                  onClick={handleRemoveDish}
                  className="px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
                >
                  Remove
                </button>
                <button
                  onClick={handleEditDishes}
                  className=" px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
                >
                  {currDishStatus === true ? "Not active" : "Active"}
                </button>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                      getSingleTag();
                    }}
                    className="px-4 py-2 text-white transition-colors duration-200 transform bg-blue-300 rounded-md  focus:outline-none"
                  >
                    Edit Tags
                  </button>
                  {showModal ? (
                    <>
                      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-xl ">
                          {/*content*/}
                          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                              <h3 className="text-3xl font-semibold">
                                Edit Tags
                              </h3>
                              <button
                                aria-label="close"
                                className="p-1 ml-auto bg-transparent text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                              >
                                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                  <ImCross
                                    style={{
                                      color: "#b91c1c",
                                      height: "17px",
                                      width: "23px",
                                      cursor: "pointer",
                                      marginTop: "5px",
                                    }}
                                  />
                                </span>
                              </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                              {currDishTags.map((el: any) =>
                                el.map((el: any) => (
                                  <div
                                    key={el.tagName}
                                    className="flex items-center gap-2"
                                  >
                                    <div className="w-full lg:w-[100px]">
                                      <label htmlFor="">{el.tagName}:</label>
                                    </div>
                                    <div>
                                      <input
                                        type="text"
                                        aria-label="Tag value"
                                        placeholder={el.tagValue}
                                        defaultValue={el.tagValue}
                                        className="block lg:px-4 pl-2 py-2 mt-2 border-2 border-gray-400 rounded-md focus:outline-none"
                                        onChange={(e) => {
                                          currDishTags.map((item: any) =>
                                            item.filter((s: any) =>
                                              s.tagValue === el.tagValue
                                                ? (s.tagValue = e.target.value)
                                                : ""
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                              <button
                                type="button"
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                onClick={() => {
                                  setShowModal(false);
                                  handleEditTags();
                                }}
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mt-3">
        <table className="w-full table-fixed border-separate border-spacing-3">
          <thead>
            <tr>
              <th className="border px-1 border-slate-300">Dishes</th>
              <th className="border px-1 border-slate-300">Categories</th>
              <th className="border px-1 border-slate-300">Status</th>
            </tr>
          </thead>
          <tbody>
            {fetchDishes.map((el: any) => (
              <tr key={el._id} className="h-[100%]">
                <td>
                  <div
                    onClick={() => {
                      setCurrDishId(el._id);
                      setSelected(el._id);
                      handleSelect(el._id);
                      setCurrDishStatus(el.isActive);
                    }}
                    className={
                      selected === el._id
                        ? "bg-gray-300 border rounded-md px-2 cursor-pointer"
                        : "border rounded-md cursor-pointer px-2 lg:min-h-[50px]"
                    }
                  >
                    {/* <strong> */}
                    {el.dishName}
                    {el.alternateDishName ? (
                      <div>
                        {" / "}
                        <span className="text-red-200">
                          {el.alternateDishName}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* </strong> */}
                  </div>
                </td>
                <td>
                  <div
                    key={el.dishId}
                    className="lg:min-h-[50px] content-center border rounded-md px-2"
                  >
                    {el.categoryName}
                    {el.alternateCategoryName ?<div>
                      {" / "}
                      <span className="text-red-200">
                        {el.alternateCategoryName}
                      </span>
                    </div>:""}
                  </div>
                </td>
                <td>
                  <div className="lg:min-h-[50px] items-center border rounded-md px-2">
                    {el.isActive === true ? "Active" : "Not active"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-5">
        <Pagination
          currentPage={page}
          totalPages={totalPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Dishes;
