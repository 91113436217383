import "./App.css";
import { useEffect } from "react";
import Login from "./Pages/Login/Login";
import Sidebar from "./Layout/Sidebar";
import AllRoutes from "./Routes/AllRoutes/AllRoutes";
import { toggleSidebar } from "./Redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const showSidebar = useSelector((store: any) => store.showSidebar);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  return (
    <div>
      {location.pathname === "/login" ? (
        <Login />
      ) : (
        <div className="lg:flex min-w-[300px] min-h-screen lg:w-full">
          <div className="hidden w-[350px] lg:block p-2 pt-16 bg-blue-300">
            <Sidebar />
          </div>
          <div className="">
            {showSidebar ? (
              <button
                className="flex text-4xl text-black items-center cursor-pointer fixed right-10 top-6 z-50"
                onClick={() => dispatch(toggleSidebar(false))}
              >
                x
              </button>
            ) : (
              <div className="lg:hidden fixed w-full z-30 border bg-blue-300 px-1 py-1 flex content-center justify-between">
                {/* <div className=" text-white text-[23px]"><strong>Annamandir</strong></div> */}
                <div className="flex items-center text-[25px]">
                  <img
                    className=""
                    src="/Annamandir_logo.png"
                    alt="Logo"
                    width="30px"
                  />
                </div>
                <svg
                  onClick={() => dispatch(toggleSidebar(!showSidebar))}
                  width="40"
                  height="40"
                  fill="black"
                  viewBox="0 0 100 80"
                  className="mt-2 mb-[-8px]"
                >
                  <rect width="50" height="5"></rect>
                  <rect y="20" width="50" height="5"></rect>
                  <rect y="40" width="50" height="5"></rect>
                </svg>
              </div>
            )}
          </div>
          <div
            className={`top-0 right-0 w-[60vw] md:w-[45vw] bg-blue-300  pt-[70px] text-white fixed h-full z-40  ease-in-out duration-300 ${
              showSidebar ? "translate-x-0 " : "translate-x-full"
            }`}
          >
            <Sidebar />
          </div>
          <div className="lg:w-full lg:pl-10 p-6 md:p-12 z-2 w-full pt-16 lg:pt-5 md:pt-16">
            <AllRoutes />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
