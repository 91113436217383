import {
  getLoggedUserDetails,
  getUserDataFailure,
  getUserDataRequest,
  getUserDataSuccess,
  setUpRecaptha,
} from "../../Redux/action";
import OtpInput from "react18-input-otp";
import { useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { login } from "../../Api/index";

const Login = () => {
  const [otp, setOtp] = useState("");
  const [flag, setFlag] = useState(false);
  const [number, setNumber] = useState<any>();
  const [result, setResult] = useState<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isAuth = localStorage.getItem("token");
  const Error = (params: any) => toast.error(params);
  const success = (params: any) => {
    toast.success(params);
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    login(number)   
      .then((res: any) => {
        if (res.data.msg === "User found") {
          dispatch(getLoggedUserDetails(res.data.userData))
          getOTP(e);
        } else if (res.data.msg === "User not found") {
          Error("User not registered");
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getOTP = async (e: any) => {
    e.preventDefault();
    if (number === "" || number === undefined || number.length < 9)
      return Error("Please enter a valid Phone Number");

    try {
      const response = await setUpRecaptha("+" + number);
      success("OTP sent");
      setResult(response);
      setFlag(true);
    } catch (err: any) {
      console.log("err", err);
    }
  };

  const resendOTP = async (e:any)=>{
    try{
      await setUpRecaptha("+" + number)
      success("OTP resent")
    }catch (err: any) {
      console.log("err", err);
    }
  }

  const verifyOTP = async (e: any) => {
    e.preventDefault();
    if (otp === "" || otp === null) return;
    try {
      dispatch(getUserDataRequest());
      await result.confirm(otp).then((result: any) => {
        console.log("UR",result.user)
        localStorage.setItem("token", result.user.accessToken);
        localStorage.setItem("apiKey", result.user.auth.config.apiKey);
        localStorage.setItem(
          "refreshToken",
          result.user.stsTokenManager.refreshToken
        );
        success("Login Successfull");
        dispatch(getUserDataSuccess(result.user));
        navigate("/meal-planning");
      });
    } catch (err: any) {
      dispatch(getUserDataFailure());
      if (err.message === "auth/code-expired") {
        localStorage.removeItem("token");
      } else {
        Error(err.message);
      }
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/meal-planning");
    }
  }, [isAuth]);
  return (
    <div className="flex flex-col justify-center min-w-[300px] max-w-xs min-h-screen md:w-96 p-7 md:p-1 lg:p-1 lg:w-96 m-auto">
      <div className="w-full px-6 py-2 m-auto bg-white rounded-md border border-black lg:max-w-xl">
        {flag === false ? (
          <form onSubmit={handleLogin} className="">
            <div className="text-center mb-8 text-lg font-bold text-gray-800">
              Sign in
            </div>
            <div>
              <PhoneInput
                country={"in"}
                value={number}
                onChange={setNumber}
                inputStyle={{ width: "100%" }}
                placeholder="Enter Phone number"
              />
              <div id="recaptcha-container" />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="ml-[23%] md:ml-[85px] lg:ml-[33%] px-4 py-2 text-white transition-colors duration-200 transform bg-blue-700 rounded-md  focus:outline-none"
              >
                Send OTP
              </button>
            </div>
            <Toaster />
          </form>
        ) : (
          <form onSubmit={verifyOTP} className="">
            <div className="text-center mb-4 text-lg font-bold text-gray-800">
              Sign in
            </div>
            <div className="lg:ml-[10%] md:ml-[10%]">
              <OtpInput
                inputStyle={{
                  width: "30px",
                  padding: ".5rem",
                  justifyContent: "center",
                  border: "1px solid black",
                }}
                value={otp}
                numInputs={6}
                onChange={setOtp}
                separator={<span>-</span>}
              />              
            </div>
            <div className="mt-6 lg:w-[80%] md:w-[80%] mx-auto justify-around flex gap-1 text-center">
              <button
                onClick={()=> window.location.reload()}
                className="px-4 py-2 text-white transition-colors duration-200 transform bg-blue-700 rounded-md  focus:outline-none"
              >
                Back
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white transition-colors duration-200 transform bg-blue-700 rounded-md  focus:outline-none"
              >
                Verify OTP
              </button>
            </div>
              <div onClick={resendOTP} className="text-center cursor-pointer mt-2 text-sm"><div id="recaptcha-container" />For resending OTP click here</div>
            <Toaster />
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
