import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MealCards from "../../Components/Meal planning/MealCards";

const MealPlanning = () => {
  const navigate = useNavigate();
  const auth = localStorage.getItem("token");
 
  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth]);

  return (
    <div>
      <div>
        <strong className="flex text-xl mb-4">Meal Planning</strong>
      </div>
      <MealCards />
    </div>
  );
};

export default MealPlanning;
