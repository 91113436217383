import * as types from "./actionTypes";
import { auth } from "../firebase.config";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber
} from "firebase/auth";

const getUserDataRequest = () => {
  return {
    type: types.GET_USER_DATA_REQUEST,
  };
};

const getUserDataSuccess = (payload: any) => {
  return {
    type: types.GET_USER_DATA_SUCCESS,
    payload,
  };
};

const getUserDataFailure = () => {
  return {
    type: types.GET_USER_DATA_FAILURE,
  };
};

const settingMealId = (payload: string) => {
  return {
    type: types.MEAL_ID_SUCCESS,
    payload,
  };
};

const toggleSidebar = (payload: boolean) => {
  return {
    type: types.TOGGLE_SIDEBAR,
    payload,
  };
};

const getLoggedUserDetails = (payload: any) => {
  return {
    type: types.GET_LOGGED_USER_DETAILS,
    payload,
  };
};



function setUpRecaptha(number: any) {
  let recaptchaVerifier:any  
  if (recaptchaVerifier) {
    recaptchaVerifier.recaptcha.reset(
      recaptchaVerifier.recaptchaWidgetId
    );
  }else
  {
    recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    )
  }
  recaptchaVerifier.render();  
  return signInWithPhoneNumber(auth, number, recaptchaVerifier) 
}



export {
  settingMealId,
  toggleSidebar,
  setUpRecaptha,
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataFailure,
  getLoggedUserDetails,
};
