import { TestBroadcast } from "../index";

export default function TestBroadcastFunc(
  dish: any,
  mobileNo: any,
  mealImageURL: string,
  messageTemplate: string
) {
  let params;
  if (!messageTemplate || messageTemplate === "test") {
    params = {
      elementName: "test",
      dishes: Object.keys(dish).length && dish.dishes.join("," + " "),
      phone: [{ phone: mobileNo && mobileNo }],
      mealImageURL: mealImageURL,
    };
  } else if (messageTemplate === "daily_special") {
    params = {
      elementName: messageTemplate,
      dishes: Object.keys(dish).length && dish.dishes.join("," + " "),
      phone: [{ phone: mobileNo && mobileNo }],
    };
  }
  return TestBroadcast(params);
}
